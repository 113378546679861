<template>
  <b-container v-if="loading">
    <b-card>
      <b-row>
        <b-col cols="12 text-center">
        <h6><strong> Enviando datos</strong></h6>
        <b-spinner variant="primary" label="Spinning"></b-spinner>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
  <b-container fluid class="py-5" v-else>
    <b-card>
      <b-row>
        <b-col cols="12 text-center">
          <strong>
            <h4 v-if="errorInResponse">{{ response.data.message }}</h4>
            <h4 v-else>Revise su casilla de email para finalizar el proceso de activación de la cuenta</h4>
          </strong>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12 text-center py-2">
          <i
            class="text-success fas fa-check-circle fa-4x"
          v-if="!errorInResponse"

          ></i>
          <i
            class="text-danger fas fa-times-circle fa-4x"
          v-else
          ></i>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12 text-center py-3">
          <strong>
            <h6 v-if="!errorInResponse" class="text-muted">
              Hemos enviado un link a su casilla de email con el cual deberá activar su cuenta para poder ingresar a la plataforma
            </h6>
          </strong>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col cols="8 text-center py-2">
          <strong>
            <b-button v-if="errorInResponse" block @click="forceUpdate">
              <span class="form-text">OK</span>
            </b-button>
            <b-button v-else block @click="goHome">
              <span class="form-text">OK</span>
            </b-button>
          </strong>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "FinishOnboarding",
  props: ["response", "loading"],
  data() {
    return {}
  },
  methods: {
    forceUpdate(){
      location.reload()
    },
    confirmStep() {
      this.$emit("confirm-step", null)
    },
    goHome(){
      if(this.shouldReload) {
        location.reload();
        return
      }

      this.$router.push({
        name: this.routeByOrigin
      }).catch(()=>{});
    }
  },
  computed: {
    ...mapGetters('user', [
      'pathByOrigin',
      'routeByOrigin',
      'shouldReload'
    ]),
    errorInResponse(){      
      try {
        return this.response.data.error !== 0;
      } catch (err) {
        console.log(err);
        return true;
      }
    },
  }
}
</script>

<style>
.unclickedPicture {
  background-color: rgba(0, 0, 0, 0.233);
}
.clickedPicture {
  background-color: rgba(0, 0, 0, 0.877);
}

.unclickedCard {
  color: rgba(0, 0, 0, 0.384);
}
.clickedCard {
  color: rgba(0, 0, 0, 0.877);
  border: 2px solid #352f77;
}
</style>
